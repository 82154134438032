<template>
  <div class="service">
      <h5 class="title">about us</h5>
      <!-- <div class="text_msg">complex-design is affiliated with Baoquanling Nongken Deju Network Service Studio.</div> -->
      <div class="text_msg">complex-design mainly offers Steam virtual prop sales service, steam official store: https://store.steampowered.com.</div>
      <div class="text_msg">To retrieve Steam Virtual Items from complex-design, you can select individual game trinkets from your personal inventory and click the "Pre-Order" button. Wait for it to cool down, and the cooldown period is responsible for the Steam Seven-Day Deal Terms. Then click the "Create Deal" button to send game items to your Steam game inventory.</div>
      <div class="text_msg">complex-design account balance does not support withdrawal, does not support the exchange of physical goods, and cannot be transferred to others. Please spend rationally. Virtual item trading is done automatically using the Steam robot. If anyone is selling you recycled in-game currency, please contact us immediately for feedback.</div>
      <div class="text_msg">complex-design prohibits any dissemination of illegal, illegal, vulgar and other information, all interactive activities of complex-design are leisure and entertainment activities designed to increase fun, please do not indulge; it is forbidden to use interactive activities for gambling/fraud/cheating/swiping points and other illegal acts, if found, we will ban it. Do not be gullible in any way to induce recharge, private transactions, etc., in order to prevent personal or property damage.</div>
      <div class="text_msg">complex-design Email: 267438914232@qq.com;</div>
      <div class="text_msg">Or you can contact us via "Customer Support" and we will respond within 3 business days;</div>
      <div class="text_msg"></div>
      <div class="text_msg"></div>
      <div class="text_msg"></div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>